import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMixpanel } from "react-mixpanel-browser";
import GoogleIcon from '@mui/icons-material/Google';
import PhoneIcon from '@material-ui/icons/Phone';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getUser } from "~/services/loginService";
import { useSelector } from "react-redux";
import checkoutServices from "~/services/checkoutServices";

function OptionsLoginComponent(props) { 
	const mixpanel = useMixpanel();
	const classes = useStyles();

	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { business } = useSelector((store) => store.business);


	const { goBackRoute, goBackRouteProps } = props.location.state;

	const onClickGoBack = () => {
		props.history.goBack();
	};
	
    const provider = new GoogleAuthProvider();
	const auth = getAuth();

    const login = async () => {
		try {
		  await signInWithPopup(auth, provider);
		  
		  const res = await checkoutServices.me();
	  
		  if (res?.data?.id) {
			localStorage.setItem("@prit-web/meData", JSON.stringify(res.data));
			localStorage.setItem("@prit-web/userId", res.data.id);
		  }
		  props.history.push(goBackRoute)
		} catch (error) {
		  alert(`Erro durante o login com Google: ${error.message}`);
		}
	};

    const handleScheduleReservationsClick = () => {
			props.history.push(`/login`, {
				goBackRoute,
				goBackRouteProps
			});
	};

	return (
		<>
			<TitleContainer
				loading={loading}
				handleClick={onClickGoBack}
				headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
				//footer={footer(submitForm)}
			>
                <Typography className={classes.message} style={{ color: "#333", textAlign:"-webkit-center",fontSize: 16 }}>
                    <strong>Para acessar essa página, faça o Login</strong>
                </Typography>
				
                <div style={{ width: "100%", justifyContent: "center", display: "grid" }}>
								<Button
									disabled={false}
									className={classes.root}
									style={{
									textTransform: "uppercase",
									fontWeight: "Bold",
									marginBottom: "5%",
									}}
									onClick={() => {login()}}
								>
									<GoogleIcon style={{ position: 'absolute', left: 16 }} />
									<span style={{ flexGrow: 1, textAlign: 'center' }}>{t("Continue Google")}</span>
								</Button>
							<Button
								disabled={false}
								onClick={() => {handleScheduleReservationsClick()}}
								className={classes.root}
								style={{
									textTransform: "uppercase",
									fontWeight: "Bold",
									backgroundColor: '#fff',
									border: "2px solid #440067",
									color: "#440067"
								}}>								
								{ <PhoneIcon style={{ position: 'absolute', left: 16 }} /> }
								{t("Continue")}
							</Button>
						</div>
			</TitleContainer>
			{/* <WhatsAppButton bottom={"30px"} /> */}
		</>
	);
}

export default withRouter(OptionsLoginComponent);
