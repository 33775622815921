import { Divider, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ColorButton from "~/components/common/colorButton/ColorButton";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMixpanel } from "react-mixpanel-browser";
import businessService from "~/services/businessService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import businessAction from "~/store/actions/businessAction";
import { DialogBuilder } from "~/utils/DialogProvider";
import CustomCardHeader from "~/components/common/customCardHeader/CustomCardHeader";
import Utils from "~/helpers/Utils";
import { ArrowBackIos } from "@material-ui/icons";

const CategorieScheduling = (props) => {
	const mixpanel = useMixpanel();
	const classes = useStyles();
	const { id } = useParams();

	const dispatch = useDispatch();
	const dialog = useContext(DialogBuilder);
	const [loading, setLoading] = useState(false);

	const pet = props?.location?.state?.feature_items;
	const coupon = props?.location?.state?.coupon;

	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();
	const { user } = useSelector((store) => store.auth);

	const [categoriesList, setCategoriesList] = useState([]);
	const [selectedCategorie, setSelectedCategorie] = useState(null);

	const goToNextPage = (category) => {
		props.history.push("/selecionar-servicos/" + business?.id, { categorie: category, pet: pet, coupon: coupon });
	};

	const loadSectors = async (categories) => {
		try {
			const sectors = await businessService.getSectors(categories.map((item) => item.id));
			localStorage.setItem("@prit-web/sectors", JSON.stringify(sectors.data));
			setLoading(false);
		} catch (err) {
			dialog
				.withMessage(t("Unable to load the sectors of this establishment"))
				.withButtonText(t("Try again"))
				.withAction(() => {
					loadSectors(categories);
				})
				.show();
			setLoading(false);
		}
	};

	const loadBusiness = () => {
		setLoading(true);
		businessService
			.getBusiness(id)
			.then((res) => {
				setCategoriesList(res.data.categories.sort((a, b) => (a?.name < b?.name ? -1 : 1)));
				dispatch(businessAction.setBusiness(res.data));
				loadSectors(res.data.categories);
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					loadBusiness();
				} else {
					dialog
						.withMessage(err?.response?.data?.message || "Ops...")
						.withButtonText(t("Try again"))
						.withAction(() => {
							loadBusiness();
						})
						.show();
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		if (business?.categories) {
			if (business.categories.length > 1) {
				setCategoriesList(business.categories.sort((a, b) => (a?.name < b?.name ? -1 : 1)));
			} else {
				goToNextPage(business.categories[0]);
			}
		} else {
			loadBusiness();
		}
	}, []);

	const footer = () => {
		return (
			<div style={{ display: "flex", justifyContent: "space-around", margin: "0px 20px", marginBottom: "20px" }}>
				<ColorButton
					disabled={!selectedCategorie}
					variant="contained"
					title={t("Next")}
					style={{
						backgroundColor: "#450067",
						color: "white",
						opacity: !selectedCategorie ? "50%" : "100%",
						width: "100%",
					}}
					className={classes.nameButton}
					onClick={() => {
						goToNextPage(selectedCategorie);
						mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
							my_custom_prop: "foo",
						});
						mixpanel.track("web - selecionou categoria", {
							my_custom_prop: "foo",
						});
					}}>
					{t("Next")}
				</ColorButton>
			</div>
		);
	};

	return (
		<Fragment>
			<TitleContainer loading={loading} footer={footer()}>
				<div className={classes.centerDiv} style={{ position: "relative", top: "0", left: "0", marginBottom: "10px" }}>
					<ArrowBackIos
						onClick={() => {
							props.history.goBack();
						}}
					/>
					{/* <Typography className={classes.selectServicesLabel}>{t("Select Service")}</Typography> */}
				</div>
				<CustomCardHeader business={business} />
				<div style={{ marginBottom: "5%", padding: "16px", paddingBottom: "0" }}>
					{business?.address && (
						<>
							<Typography className={classes.descriptionTextAddress}>
								{`${Utils.capitalize(business.address.street)}, ${business.address.number} - ${Utils.capitalize(
									business.address.neighborhood
								)},  ${Utils.capitalize(business.address.city)} - ${
									business.address.state
								} - CEP ${business.address.postalCode}`}
							</Typography>
						</>
					)}
				</div>
				<Divider />
				<div>
					<Typography className={classes.levelTitle} style={{padding:"16px 0 0 10px"}}>{t("Selecionar Categoria")}</Typography>
					{categoriesList?.length > 0 && (
						<div style={{ display: "flex", justifyContent: "space-evenly", width: "auto", maxWidth: "400px" }}>
							<div
								style={{
									display: "flex",
									flexFlow: "wrap",
									maxWidth: "500px",
									justifyContent: "space-evenly",
								}}>
								{categoriesList.map((item) => {
									return (
										<div
											style={{
												margin: "20px 0",
												boxShadow: "0px 3px 5px rgba(0,0,0,0.5)",
												borderRadius: "20%",
												width: "25vw",
												height: "25vw",
												maxHeight: "100px",
												maxWidth: "100px",
											}}
											onClick={() => {
												setSelectedCategorie(item);
											}}>
											{selectedCategorie?.id === item?.id && (
												<div
													style={{
														backgroundColor: "#450067",
														opacity: 0.8,
														width: "25vw",
														height: "calc(25vw*0.7)",
														maxHeight: "70px",
														maxWidth: "100px",
														display: "flex",
														justifyContent: "center",
														flex: "33%",
													}}
													className={classes.colorClickBtn}>
													<CheckCircleIcon
														style={{
															backgroundColor: "#450067",
															opacity: 0.8,
															fontSize: "25px",
															alignSelf: "center",
														}}
														className={classes.checkCategory}
													/>
												</div>
											)}
											<img src={item?.image} alt={item.name} className={classes.RectangleImage} />
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													height: "calc(25vw*0.3)",
													maxHeight: "30px",
													padding: "10%",
													marginTop: "-7%",
												}}>
												<h1 className={classes.nameCategorieSelection}>
													{business?.language === "portuguese" ? item?.name : item?.nameEng}
												</h1>
											</div>
										</div>
									);
								})}
								{categoriesList % 3 == 1 && (
									<div
										style={{
											margin: "20px 0",
											width: "25vw",
											height: "25vw",
											maxHeight: "100px",
											maxWidth: "100px",
										}}></div>
								)}
								{categoriesList % 3 != 0 && (
									<div
										style={{
											margin: "20px 0",
											width: "25vw",
											height: "25vw",
											maxHeight: "100px",
											maxWidth: "100px",
										}}></div>
								)}
								<div
									style={{
										margin: "20px 0",
										width: "25vw",
										height: "25vw",
										maxHeight: "100px",
										maxWidth: "100px",
									}}></div>
							</div>
						</div>
					)}
				</div>
			</TitleContainer>
		</Fragment>
	);
};
export default withRouter(CategorieScheduling);
