/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { createRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog/index";
import { TextFieldComponent } from "~/components/common/formItem";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import Utils from "~/helpers/Utils";
import useInterval from "../../utils/useInterval";
import useStyles from "./styles";
// Mixpanel
import { useMixpanel } from "react-mixpanel-browser";
import { useTranslation } from "react-i18next";
import {
	sendMessageCodeWhatsApp,
	sendMessageWavy,
	sendMsgWavy,
	sendMsgWhatsApp,
	sendNumber,
	sendSMSCodeTwilio,
	sendSmsTwilio,
	sendTokenFirebase,
	verifyCode,
} from "~/services/loginService";
import userUpdateService, { getUser } from "~/services/userUpdateService";
import checkoutServices from "~/services/checkoutServices";
import googleReserveService from "~/services/googleReserveService";
import { DialogBuilder } from "~/utils/DialogProvider";
import WhatsAppButton from "../common/whatsappButton/whatsappButton";

const RESEND_INTERVAL = 60;

function CodePage(props) {
	const mixpanel = useMixpanel();
	const classes = useStyles();

	const { t } = useTranslation();
	const { phone, ddi, validateBy, sessionInfo, goBackRoute, goBackRouteProps } = props.location.state;
	const [loading, setLoading] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [messageError, setMessageError] = useState("Ops");
	const [code, setCode] = useState("Ops");

	const code1 = createRef(null);
	const code2 = createRef(null);
	const code3 = createRef(null);
	const code4 = createRef(null);
	const code5 = createRef(null);
	const code6 = createRef(null);
	const refs = [code1, code2, code3, code4, code5, code6];

	const [resendCounter, setResendCounter] = useState(RESEND_INTERVAL);
	const [isResendClick, setIsResendClick] = useState(0);
	const [isResendActive, setIsResendActive] = useState(false);
	const [verifyCodeBy, setVerifyCodeBy] = useState(validateBy);
	const [verificationId, setVerificationId] = useState(sessionInfo);

	const dialog = useContext(DialogBuilder);

	useInterval(
		() => {
			setResendCounter(resendCounter - 1);
		},
		resendCounter > 0 ? 1000 : null
	);

	useEffect(() => {
		code1.current.focus();
		mixpanel.identify(ddi + phone, {
			my_custom_prop: "foo",
		});
	}, []);

	useEffect(() => {
		if (resendCounter === 0) {
			setIsResendActive(true);
		}
	}, [resendCounter]);

	const onClickGoBack = () => {
		props.history.goBack();
	};

	const nextPage = () => {
		checkoutServices.me().then((res) => {
			let rwg_token = localStorage.getItem("@prit-web/rwg_token");
			if (rwg_token && (!res.data.rwg_token || rwg_token !== res.data.rwg_token)) {
				googleReserveService.googleReservePost(rwg_token);
				userUpdateService.pachUserUpdate(res?.data?.id, {
					rwg_token: rwg_token,
				});
			}
			if (res?.data?.id) {
				localStorage.setItem("@prit-web/meData", JSON.stringify(res?.data));
				localStorage.setItem("@prit-web/userId", res?.data?.id);
			}
			if (!res?.data?.name) {
				props.history.replace("/login/personal-information", {
					goBackRoute: goBackRoute,
					goBackRouteProps: goBackRouteProps,
				});
				mixpanel.track("Dados pessoais-web", {
					my_custom_prop: "foo",
				});
			} else {
				props.history.replace(goBackRoute, { ...goBackRouteProps });
			}
		});
	};

	const handleSubmit = () => {
		const code = refs.map((c) => c.current.value).join("");
		if (code.length < 6) {
			return;
		}
		setLoading(true);
		if (!ddi || !phone || ddi.length === 0 || phone.length === 0 || ddi + phone == null) {
			dialog
				.withMessage(`Existe algo errado com seu telefone, por favor verifique e tente novamente!`)
				.withAction(() => props.history.replace("/login", { goBackRoute, goBackRouteProps }))
				.show();
			return;
		}
		switch (verifyCodeBy) {
			case "wavy":
				sendMessageWavy(ddi + phone, code)
					.then((response) => {
						sendTokenFirebase(response.data.message)
							.then((res) => {
								nextPage();
							})
							.catch((err) => {
								setMessageError(err?.response?.data?.message);
								setOpenError(true);
								setLoading(false);
							});
					})
					.catch(function (err) {
						setMessageError(err?.response?.data?.message);
						setOpenError(true);
						setLoading(false);
					});
				break;
			case "twilio":
				sendSMSCodeTwilio(ddi + phone, code)
					.then((response) => {
						sendTokenFirebase(response.data.message)
							.then((res) => {
								nextPage();
							})
							.catch((err) => {
								setMessageError(err?.response?.data?.message);
								setOpenError(true);
								setLoading(false);
							});
					})
					.catch(function (err) {
						setMessageError(err?.response?.data?.message);
						setOpenError(true);
						setLoading(false);
					});
				break;
			case "whatsapp":
				sendMessageCodeWhatsApp(ddi + phone, code)
					.then((response) => {
						sendTokenFirebase(response.data.message)
							.then((res) => {
								nextPage();
							})
							.catch((err) => {
								setMessageError(err?.response?.data?.message);
								setOpenError(true);
								setLoading(false);
							});
					})
					.catch(function (err) {
						setMessageError(err?.response?.data?.message);
						setOpenError(true);
						setLoading(false);
					});
				break;
			default:
				verifyCode({ verificationId }, code)
					.then((res) => {
						nextPage();
					})
					.catch(function (err) {
						setMessageError(err?.response?.data?.message || err?.message);
						setOpenError(true);
						setLoading(false);
					});
		}
	};

	const onChangeInput = (e, index) => {
		setCode(refs.map((c) => c.current.value).join(""));
		if (e.target.value.length >= 1 && index < 5) {
			refs[index + 1].current.focus();
		}
	};

	const onKeyDownInput = (e, index) => {
		if (e.key === "Backspace") {
			if (index === 5 && e.target.value !== "") {
				return;
			}
			if (index === 5 && e.target.value === "") {
				refs[index - 1].current.focus();
			}
			if (index > 0) {
				refs[index - 1].current.focus();
			}
		}
	};

	useEffect(() => {
		if (!ddi || !phone || ddi.length === 0 || phone.length === 0) {
			dialog
				.withMessage(`Existe algo errado com seu telefone, por favor verifique e tente novamente!`)
				.withAction(() => props.history.replace("/login", { goBackRoute, goBackRouteProps }))
				.show();
		}
	}, []);

	const factory = () => {
		let codeInformation = [];
		for (let i = 0; i <= 5; i++) {
			codeInformation.push({
				id: `code-${i}`,
				name: `code${i}`,
				index: i,
			});
		}
		return codeInformation;
	};

	const opeWhatsApp = () => {
		window.open("https://api.whatsapp.com/send?phone=5516997775469&text=Gostaria%20de%20ajuda%20com%20o%20SMS!");
	};

	const inputsData = factory();

	const footer = () => {
		return (
			<Grid item style={{ textAlign: "center" }}>
				{isResendClick === 2 && (
					<>
						<Button
							onClick={() => {
								opeWhatsApp();
							}}>
							<Typography className={classes.subtitle} style={{ fontSize: 11 }}>
								{t("If not, seek support by clicking")}
							</Typography>
						</Button>
					</>
				)}
				<Grid item style={{ margin:10, paddingTop: 10, textAlign: "center" }}>
					<Button
						className={classes.button}
						disabled={code.length < 6}
						style={{ marginTop: 20, marginBottom: "10%", backgroundColor: code.length < 6 ? "#dddddd" : "rgb(69 0 103)" }}
						onClick={() => {
							handleSubmit();
							mixpanel.identify(ddi + phone, {
								my_custom_prop: "foo",
							});
							mixpanel.track("web - digitou código avançar", {
								my_custom_prop: "foo",
							});
						}}>
						{t("go")}
					</Button>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<TitleContainer
				loading={loading}
				handleClick={onClickGoBack}
				headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
				footer={footer()}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div>
						<Typography className={classes.title}>
							<strong>{t("Enter the confirmation code")}</strong>
						</Typography>
					</div>
					<div style={{ width: "100%", padding: "25px 0" }}>
						<div style={{ textAlign: "center" }}>
							<Typography className={classes.subtitle} style={{color:"#5f6368"}}>
								{t("Your number")} {ddi} <strong>{phone}</strong>
							</Typography>
						</div>
						
						<div style={{ textAlign: "center" }}>
							<Button className={classes.buttonAlterar} onClick={() => onClickGoBack()}>
								{t("Change")}
							</Button>
						</div>
					</div>
					<>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
							{inputsData.map((item, i) => {
								return (
									<TextField
										className="numbers"
										codeInput
										style={{
											aparence: "textfield",
											width: "38px",
										}}
										id={item.id}
										key={item.id}
										variant="outlined"
										name={item.name}
										type="number"
										data-index={4}
										inputRef={refs[item.index]}
										onChange={(e) => {
											if (e.target.value.length > 1) {
												e.target.value = e.target.value.charAt(0);
											}
											onChangeInput(e, item.index);
										}}
										inputProps={{
											maxLength: 1,
											onChange: (e) => Utils.numberMask(e),
										}}
										onKeyDown={(e) => {
											onKeyDownInput(e, item.index);
										}}
										onKeyPress={(event) => {
											if (event.key === "Enter" && i === 5) {
												event.preventDefault();
												handleSubmit();
												mixpanel.identify(ddi + phone, {
													my_custom_prop: "foo",
												});
												mixpanel.track("web - digitou código avançar", {
													my_custom_prop: "foo",
												});
											}
										}}
									/>
								);
							})}
						</div>

						<Grid item container alignContent="center" direction="column" style={{ paddingTop: 15 }} spacing={3}>
							{!isResendActive && (
								<>
									
									<Grid item style={{ textAlign: "center" }}>
										<Typography className={classes.subtitle} style={{color:"#5f6368"}}>{t("Didn't receive the code? Hold")}</Typography>
										<Typography className={classes.subtitle} style={{color:"#5f6368"}}>
											{t("60s that the option to resend will appear")}
										</Typography>
									</Grid>
								</>
							)}

							{!isResendActive && (
								<Grid item style={{ textAlign: "center", padding: 0 }}>
									<p style={{ color: "red" }}>{`${t("Resend on")} ${resendCounter}s`}</p>
								</Grid>
							)}

							{isResendActive &&
								(ddi !== "+55" || isResendClick >= 2 ? (
									<Grid style={{ justifyContent: "center", alignSelf: "center", marginTop: "20px" }}>
										<Button
											className={classes.whatsApp}
											variant="outlined"
											onClick={() => {
												if (resendCounter > 0) return;
												setIsResendClick(isResendClick + 1);
												setVerifyCodeBy("firebase");
												sendNumber(ddi + phone).then((res) => {
													setVerificationId(res.verificationId);
												});
											}}>
											{t("Send by SMS")}
										</Button>
									</Grid>
								) : isResendClick === 0 ? (
									<Grid style={{ justifyContent: "center", alignSelf: "center", marginTop: "20px" }}>
										<Button
											className={classes.whatsApp}
											variant="outlined"
											onClick={() => {
												if (resendCounter > 0) return;
												setIsResendClick(isResendClick + 1);
												setResendCounter(RESEND_INTERVAL);
												setIsResendActive(false);
												setVerifyCodeBy("wavy");
												sendMsgWavy(ddi + phone);
											}}>
											{t("Send by SMS")}
										</Button>
									</Grid>
								) : (
									<Grid style={{ justifyContent: "center", alignSelf: "center", marginTop: "20px" }}>
										<Button
											className={classes.whatsApp}
											variant="outlined"
											onClick={() => {
												if (resendCounter > 0) return;
												setIsResendClick(isResendClick + 1);
												setResendCounter(RESEND_INTERVAL);
												setIsResendActive(false);
												setVerifyCodeBy("twilio");
												sendSmsTwilio(ddi + phone, true);
											}}>
											{t("Send by WhatsApp")}
										</Button>
									</Grid>
								))}
						</Grid>
					</>
				</div>
				<ErrorDialog onOpen={openError} message={messageError} onOk={() => setOpenError(false)} />
				<div id="recaptcha-container"></div>
			</TitleContainer>
			{/* <WhatsAppButton bottom={"120px"} rigth={"20px"} /> */}
		</>
	);
}

export default withRouter(CodePage);
